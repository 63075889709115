import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../layout'
import { Rules, SEO, Action } from '../components'

class Rule extends React.Component {
  render() {
    const rule = this.props.data.markdownRemark
    const { title, date } = rule.frontmatter

    return (
      <Layout location={this.props.location}>
        <SEO />
        <Rules title={title} subtitle={date}>
          <div dangerouslySetInnerHTML={{ __html: rule.html }} />
        </Rules>

        <div className="u-bg-black2 u-Pt-xl u-Pb-xl">
          <Action
            image="moneyChart"
            title={
              <>
                Join Developer Program
                <br />
                and start selling apps
              </>
            }
            button={{
              link: process.env.GATSBY_CONSOLE_URL,
              label: 'Join Developer Program',
            }}
            link={{ link: '/', label: 'browse the docs' }}
          />
        </div>
      </Layout>
    )
  }
}

Rule.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Rule

export const query = graphql`
  query ruleQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        index
      }
      frontmatter {
        slug
        title
        author
        excerpt
        categories
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
